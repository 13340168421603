<template>
  <b-container fluid>
    <b-modal id="message" scrollable :title="$t('messages.send_message_to') + ' ' + emailTo " size="lg" hide-footer static centered no-close-on-backdrop>
      <div class="row">
        <div class="theMessage" :class="{theMessageCurrent: currentUserEmail === item.from }" v-for="item in singleMessage" :key="item.token">
          <span>{{ item.message }} <span class="theDate">{{ item.created_at }}</span></span>
        </div>
        <div class="col-sm-12 mb-sm-1">
          <textarea v-model="emailText" class="textarea form-control" rows="1" :placeholder="$t('messages.add_message')"></textarea>
        </div>
        <div class="col-sm-6 col-6 text-left"></div>
        <div class="col-sm-6 col-6 text-right">
          <div class="send-btn">
            <button type="submit" class="btn btn-primary" v-on:click="sendMessageTo()">{{ $t('messages.send_message') }}</button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="theServices" :title="$t('calendar.select_service')" scrollable hide-footer static centered no-close-on-backdrop>
      <div class="form-group chat-search-data m-0">
        <input type="text" class="form-control round mb-4" id="service-search" :placeholder="$t('calendar.search_service')" v-model="searcheS" @input="filteredListService">
      </div>
      <b-row>
        <b-form-group class="col-sm-12 employeeDetails" v-for="(emp, id) in optionsServices" :key="id" v-model="emp.id" :ref="'services_'+emp.id">
          <b-card no-body style="border-bottom: 1px solid #dfdfdf;" @click="setService(emp)">
            <b-row no-gutters>
              <b-col md="2">
                <b-card-img :src="emp.profile_image || require('../../assets/images/page-img/08.jpg')" style="height: 50px; width: auto;"></b-card-img>
              </b-col>
              <b-col md="1"></b-col>
              <b-col md="9">
                <div class="employeeName">{{ emp.name }}</div>
                <div>{{ emp.description }}</div>
                <div>{{ $t('calendar.duration') }}: {{ emp.duration }} {{ emp.duration_type }}</div>
                <div>{{ $t('calendar.price') }}: {{ emp.price }} {{ priceCurrency }}</div>
              </b-col>
            </b-row>
          </b-card>
        </b-form-group>
      </b-row>
    </b-modal>
    <b-modal id="theEmployee" :title="$t('calendar.select_employee')" scrollable hide-footer static centered no-close-on-backdrop>
      <div class="form-group chat-search-data m-0">
        <input type="text" autocomplete="off" class="form-control round mb-4" id="employee-search" :placeholder="$t('calendar.search_employee')" v-model="searche" @input="filteredListEmployee">
      </div>
      <b-row>
        <b-form-group class="col-sm-12 employeeDetails d-none" :ref="'employee_00000'">
          <b-card no-body style="border-bottom: 1px solid #dfdfdf;" @click="setEmployee('first')">
            <b-row no-gutters>
              <b-col xs="2" >
                <i class="ri-user-shared-line" style="color: black; font-size: 20px;"></i>
              </b-col>
              <b-col xs="9">
                <div class="employeeName">
                  {{ $t('calendar.first_with_availability') }}
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-form-group>
        <b-form-group class="col-sm-12 employeeDetails" v-for="(emp, id) in companyEmployees" :key="id" v-model="emp.id" :ref="'employee_'+emp.id">
          <b-card no-body style="border-bottom: 1px solid #dfdfdf;" @click="setEmployee(emp)">
            <b-row no-gutters>
              <b-col xs="2">
                <b-card-img :src="emp.logo || require('../../assets/images/page-img/08.jpg')" style="height: 50px; width: auto;"></b-card-img>
              </b-col>
              <b-col xs="9">
                <div class="employeeName">{{ emp.name }}</div>
              </b-col>
            </b-row>
          </b-card>
        </b-form-group>
      </b-row>
    </b-modal>
    <b-modal id="reschedule" size="xl" hide-footer static centered>
      <template #modal-title="">
        <span v-if="selectedCompanyName" style="font-weight: bold;">{{ selectedCompanyName }}: </span>
        {{ $t('customer_.book_again') }}
      </template>
      <div class="row">
        <input type="hidden" ref="eventID" />
        <div class="col-sm-6 row m-0 p-0">
          <div class="col-sm-12 pb-0">
            <b>{{ $t('calendar.services') }}:</b>
            <b-button variant="success" @click="changeServices" class="mb-1 mr-1 change float-right btn-sm" style="font-size: 11px;">{{ $t('calendar.add_service') }}</b-button>
          </div>
          <div class="col-sm-12 pt-0 text-right" v-if="activeServices.length > 0">
            <div v-for="(emp, id) in activeServices" :key="id" class="activeServices">
              {{ emp.name }}({{ emp.duration }} {{ emp.duration_type}}) - {{ emp.price }} {{ priceCurrency }}
              <span @click="removeService(emp)"><i class="ri-close-circle-fill" style="color: red; cursor: pointer"></i></span>
            </div>
          </div>
          <div class="col-sm-12 pt-0">
            <b>{{ $t('extra.subtotal') }}:</b> <span ref="eventPriceSubtotal" class="eventPriceSubtotal font-weight-bold" style="color:#27b345">{{ selectedServicePrice }} {{ priceCurrency }}</span>
            <br>
            <span class="eventPriceTotal"><b>{{ $t('calendar.total_price') }}:</b> <span ref="eventPrice" class="font-weight-bold" style="color:#27b345">{{ totalPrice }} {{ priceCurrency}}</span></span>
          </div>
        </div>
        <div class="col-sm-6 row m-0 p-0">
          <div class="col-sm-5">
            <b>{{ $t('calendar.realised_by') }}:</b><br>
            <img src="" ref="employeeLogo" class="employee_logo"/>
          </div>
          <div class="col-sm-7">
            <b-button variant="success" ref="employeeName" @click="changeEmployee" class="mb-1 mr-1 change btn-sm" style="font-size: 11px;"><i class="ri-bill-fill"></i>Change</b-button>
            <br>
            <input type="hidden" ref="employeeToken">
            <i class="ri-mail-line"></i> <span ref="employeeEmail" class="send_message" v-on:click="sendMessageToEmployee()"></span><br>
            {{ $t('calendar.bookings_for_that_date') }}: <a ref="employeeBooking">0</a>
          </div>
        </div>
        <div class="col-sm-12 m-0">
          <b>{{ $t('feature.observations') }}</b>
          <b-form-textarea class="description" :placeholder="$t('feature.observations')" v-model="descriptionEvent"></b-form-textarea>
        </div>
        <div class="col-sm-12 m-0 p-0">
          <hr>
          <b-row v-if="selectedCompany && activeServicesId.length && selectedEmployee.length">
            <b-form-group class="col-sm-12 m-0">
              <iq-card class="m-2">
                <template v-slot:body>
                  <div class="row">
                    <div class="col-sm-12 m-0 text-center">
                      <b-button variant="primary" class="mr-2" v-if="previousWeek !== 'false'" @click="setWeek(previousWeek)">{{ $t('booking.prev_week') }}</b-button>
                      {{currentWeek}}
                      <b-button variant="primary" class="mr-2" @click="setWeek(nextWeek)">{{ $t('booking.next_week') }}</b-button>
                    </div>
                  </div>
                </template>
              </iq-card>
            </b-form-group>
            <b-form-group class="col-sm-12">
              <iq-card>
                <template v-slot:body>
                  <div class="row">
                    <div class="col-12"  v-if="theDates.length === 0">
                      <div>{{ $t('booking.week_reserved') }} {{ $t('booking.check') }} <b-button variant="primary" class="mr-2" @click="setWeek(nextWeek)">{{ $t('booking.next_week') }}</b-button></div>
                    </div>
                    <div class="col m-0 pr-1 p-0 fontSize" v-for="value in theDates" :key="value.typeOfDay">
                      <div class="text-center" style="font-weight: bold; background: rgb(86 159 247);color: #fff">
                        {{ $t('booking.' + value.typeOfDay) }} <br>
                        {{ value.theDate }}
                      </div>
                      <div v-for="dat in value.values" :key="dat.sDate" @click="setDate(value, dat)" :id="value.theDateSend + '_' + dat.start" class="dateButton fontSize1" >
                        {{ dat.start }}
                      </div>
                    </div>
                  </div>
                </template>
              </iq-card>
            </b-form-group>
          </b-row>
        </div>
        <div class="col-sm-12 row" v-if="device !== 'ios' && selectedCompany && activeServicesId.length && selectedEmployee.length && visiblePayments">
          <b-form-group class="col-sm-12">
            <label>{{ $t('booking.make_payment') }}:</label><br>
            <b-form-radio class="text-uppercase" inline v-model="user.payment" v-for="(val, id) in availablePayments" :value="val" :key="id">{{ val === 'stripe' ? 'card' : val }}</b-form-radio>
          </b-form-group>

          <b-form-group class="col-sm-12" v-show="user.payment === 'cash'">
            <b-button type="submit" variant="primary" class="col-sm-12" @click="makeBooking(user)">{{ $t('booking.pay_cash') }}</b-button>
          </b-form-group>
          <b-form-group class="col-sm-12" v-show="user.payment === 'paypal'" id="paypalPayment">
          </b-form-group>
          <b-form-group class="col-sm-12" v-if="user.payment === 'stripe'" id="stripePayment">
            <stripe-element-card
              ref="elementRef"
              :pk="stripeUserId"
              @token="tokenCreated"
            />
            <b-button type="submit" variant="primary" class="col-sm-12" @click="payWithStripe" v-if="!paymentInProgress">{{ $t('booking.pay_stripe') }}</b-button>
          </b-form-group>
          <b-form-group class="col-sm-12" v-if="user.payment === 'revolut'" id="revolutPayment">
            <b-button type="submit" variant="primary" class="col-sm-12" @click="createRevolutApiOrder">{{ $t('booking.pay_revolut') }}</b-button>
          </b-form-group>
          <div class="col-sm-12 pt-0" v-if="paymentError" style="color: red; font-size: 13px;">{{ paymentError }}</div>
        </div>
        <div class="col-sm-12 row" v-show="device === 'ios' && selectedCompany && activeServicesId.length && selectedEmployee.length && visiblePayments">
          <b-form-group class="col-sm-12">
            <b-button type="submit" variant="primary" class="col-sm-12" @click="payWithIOS(user)" v-if="!paymentInProgress">{{ $t('booking.make_payment') }}</b-button>
          </b-form-group>
        </div>
      </div>
    </b-modal>
    <b-modal id="newBooking" title="New booking" size="lg" hide-footer static centered>
      <div class="row">
        <div class="col-sm-3">
          <b-form-group label-for="radius" :label="$t('customer_.radius')">
            <b-form-select v-model="theRadius" :options="radiuses" id="radius" @change="updateCompanies">
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-sm-3">
          <b-form-group label-for="category" :label="$t('customer_.category')">
            <b-form-select v-model="theCategory" :options="categories" id="category" @change="updateCompanies">
              <template v-slot:first>
                <b-form-select-option value="all">{{ $t('customer_.all_categories') }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-sm-6">
          <b-form-group label-for="company-search" :label="$t('customer_.company_name')">
            <input type="text" class="form-control round mb-4" style="height: 39px;" id="company-search" :placeholder="$t('customer_.company_name')" v-model="searchCompany" @input="updateCompanies">
          </b-form-group>
        </div>
        <div class="col-md-4 col-sm-12" style="height: 550px; overflow-x: auto;">
          <div class="row p-0 displayCompanies"  v-for="(item, key) in displayCompanies" :key="key" @mouseenter="showInMap(item)" @click="selectCompany(item)">
            <div class="col-sm-12">
              <img :src="item.logo || require('../../assets/images/12booky-logo-black.jpg')" style="width: 100%; height: 100px; object-fit: contain;"/>
              <span class="company_name">
                {{ item.name }}
              </span>
              <span class="company_address">
                 {{ item.address }} - {{ item.distance }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-sm-12">
          <gmap-map
            ref="gmap"
            :center="center"
            :zoom="16"
            style="width:100%;  height: 550px;"
          >

            <gmap-marker
              :key="index"
              v-for="(m, index) in displayCompanies"
              :position="m.position"
              @click="toggleInfoWindow(m,index)">
            </gmap-marker>

            <gmap-info-window
              :options="infoOptions"
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen=false"
            >
              <div v-html="infoContent"></div>
            </gmap-info-window>

          </gmap-map>
        </div>
      </div>
    </b-modal>
    <b-modal id="addNewBooking" size="xl" hide-footer static centered no-close-on-backdrop no-close-on-esc>
      <b-row>
        <b-col sm="12">
          <iq-card body-class=" profile-page p-0">
            <template v-slot:body>
              <div class="profile-header">
                <div class="cover-container">
                  <b-img v-bind:src="companyInfo.homePicture" alt="profile-bg" rounded fluid class="w-100" style="max-height: 200px;
    object-fit: cover;"/>
                  <ul class="header-nav d-flex flex-wrap justify-end p-0 m-0">
                    <li><b-link href="javascript:void(0);"><i class="ri-phone-fill"></i></b-link></li>
                  </ul>
                </div>
                <div class="profile-info p-4">
                  <b-row>
                    <b-col md="6" sm="12">
                      <div class="user-detail pl-5">
                        <div class="d-flex flex-wrap align-items-center">
                          <div class="profile-img pr-4">
                            <b-img v-bind:src="companyInfo.logo" alt="profile-img" fluid class="avatar-130" />
                          </div>
                          <div class="profile-detail d-flex align-items-center">
                            <h3>{{ companyInfo.name }}</h3>
                          </div>
                          <p class="m-0 pl-3"> {{ companyInfo.address }}, {{ companyInfo.city }} </p>
                        </div>
                      </div>
                    </b-col>
                    <b-col md="6" sm="12">
                      <tab-nav :pills="true" id="pills-tab" class="profile-feed-items d-flex justify-content-end">
                        <tab-nav-items :active="true" id="pills-feed-tab" href="#profile-about" ariaControls="pills-home" role="tab" :ariaSelected="true" title="About" />
                        <tab-nav-items :active="false" id="pills-activity-tab" href="#profile-book" ariaControls="pills-profile" role="tab" :ariaSelected="false" title="Rezerva" />
                        <tab-nav-items :active="false" id="pills-friend-tab" href="#profile-shop" ariaControls="pills-contact" role="tab" :ariaSelected="false" title="Shop" />
                      </tab-nav>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col sm="12">
          <tab-content>
            <tab-content-item :active="true" id="profile-about" aria-labelled-by="pills-feed-tab">
              <b-row>
                <b-col lg="3" class="profile-left">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">About</h4>
                    </template>
                    <template v-slot:body>
                      <p class="news-detail mb-0" v-html="companyInfo.aboutUsText"></p>
                    </template>
                  </iq-card>
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">Galerie</h4>
                    </template>
                    <template v-slot:body>
                      <LightBox
                        ref="lightbox"
                        :media="media"
                        :show-caption="true"
                        :show-light-box="false"
                      />
                      <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                        <li v-for="(item,index) in media" :key="index" class="col-md-4 col-6 pl-1 pr-0 pb-1">
                          <b-link href="#" @click="openGallery(index)">
                            <b-img :src="item.thumb" alt="gallary-image" fluid style="height: 70px;object-fit: cover;"/>
                          </b-link>
                        </li>
                      </ul>
                    </template>
                  </iq-card>
                </b-col>
                <b-col lg="6" class="profile-center">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">Echipa</h4>
                    </template>
                    <template v-slot:body>
                      <div class="d-flex flex-wrap user-post-data mb-4 clearfix border-bottom" v-for="(item, key) in companyInfo.employeesList" :key="key">
                        <div class="media-support-user-img mr-3">
                          <b-img rounded="circle" fluid :src="item.logo" alt=""/>
                        </div>
                        <div class="media-support-info mt-2">
                          <h5 class="mb-0">{{ item.fname }} {{ item.lname }}</h5>
                        </div>
                        <p class="mb-0 text-secondary">{{ item.description }}</p>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
                <b-col lg="3" class="profile-right">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">Program</h4>
                    </template>
                    <template v-slot:body>
                      <div class="" v-if="companyProgram.monday">Monday: {{ companyProgram.monday }}</div>
                      <div class="" v-if="companyProgram.tuesday">Tuesday: {{ companyProgram.tuesday }}</div>
                      <div class="" v-if="companyProgram.wednesday">Wednesday: {{ companyProgram.wednesday }}</div>
                      <div class="" v-if="companyProgram.thursday">Thursday: {{ companyProgram.thursday }}</div>
                      <div class="" v-if="companyProgram.friday">Friday: {{ companyProgram.friday }}</div>
                      <div class="" v-if="companyProgram.saturday">Saturday: {{ companyProgram.saturday }}</div>
                      <div class="" v-if="companyProgram.sunday">Sunday: {{ companyProgram.sunday }}</div>
                    </template>
                  </iq-card>
                  <iq-card v-show="companyInfo.map === 'true'">
                    <template v-slot:headerTitle>
                      <h4 class="card-title">Harta</h4>
                    </template>
                    <template v-slot:body>
                      <p class="news-detail mb-0">
                        <gmap-map
                          ref="gmaps"
                          :center="{ lat: companyInfo.latitude, lng: companyInfo.longitude }"
                          :zoom="16"
                          style="width:100%;  height: 100px;"
                        ></gmap-map>
                      </p>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
            </tab-content-item>
            <tab-content-item :active="false" id="profile-book" aria-labelled-by="pills-feed-tab">
              <calendar-book v-if="companyInfo.company" :theuser="user" :thecompanytoken="companyInfo.company"></calendar-book>
            </tab-content-item>
            <tab-content-item :active="false" id="profile-shop" aria-labelled-by="pills-feed-tab">
              <product-listing v-if="companyInfo.company" :theuser="user" :thecompanytoken="companyInfo.company"></product-listing>
            </tab-content-item>
          </tab-content>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="meet" no-fade="no-fade" size="lg" static hide-footer centered @close="closeMeet">
      <div class="row">
        <video-conference v-if="meetingId" :theId="meetingId"></video-conference>
      </div>
    </b-modal>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('customer_.bookings') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="addNewBooking">{{ $t('customer_.add_booking') }}</b-button>
          </template>
          <template v-slot:body>
            <div style="width: 100%">
              <v-text-field
                v-model="search"
                append-icon="ri-search-line"
                :label="$t('customer_.bookings_search')"
                single-line
                hide-details
              ></v-text-field>
            </div>
            <v-data-table
              :headers="headers"
              :items="rowData"
              :search="search"
              :footer-props="{
              prevIcon: 'ri-arrow-left-line',
              nextIcon: 'ri-arrow-right-line'
            }"
            >
              <template v-slot:item.start="{ item }">
                <small>{{ item.start }}</small>
              </template>
              <template v-slot:item.company="{ item }">
                {{ item.company }}<br>
                <small>{{ item.token }}</small>
              </template>
              <template v-slot:item.employee_name="{ item }">
                <div v-if="item.employee_logo"><img :src="item.employee_logo" style="width:50px; height: 50px; object-fit: cover;"></div>
                <span @click="appendMessage(item)" v-if="item.employee_messages" style="text-decoration: underline; cursor: pointer">{{ item.employee_name }}</span>
                <span v-if="!item.employee_messages">{{ item.employee_name }}</span>
              </template>
              <template v-slot:item.total_to_pay="{ item }">
                {{ item.total_to_pay }} <br>
                <small>{{ item.payment_selected }}</small>
              </template>
              <template v-slot:item.actions="{ item }">
                <button v-if="item.active" type="button" class="btn btn-default text-success p-0 btn-mic" @click="gotoMeet(item.token)">
                  {{ $t('2023.gotomeet') }}
                </button>
                <button type="button" class="btn btn-default text-success p-0 btn-mic ml-1" @click="reschedule(item)">
                  {{ $t('customer_.book_again') }}
                </button> &nbsp;&nbsp;
                <button v-if="item.active" type="button" class="btn btn-default text-danger p-0 btn-mic" @click="removeData(item.token)">
                  {{ $t('employees_list.delete') }}
                </button>
              </template>
            </v-data-table>

          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import { core } from '../../config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'
import { StripeElementCard } from '@vue-stripe/vue-stripe'
import { Capacitor } from '@capacitor/core'
import { Geolocation } from '@capacitor/geolocation'
import { Browser } from '@capacitor/browser'
import LightBox from 'vue-it-bigger'
import CalendarBook from '@/views/Apps/Calendar/CalendarBook'
import ProductListing from '@/views/Customer/ProductListing'
import 'vue-it-bigger/dist/vue-it-bigger.min.css'
import VideoConference from '@/views/Company/VideoCall'

export default {
  name: 'BookingsList',
  components: {
    CalendarBook,
    ProductListing,
    StripeElementCard,
    LightBox,
    VideoConference
  },
  mounted () {
    core.index()
    this.getBookings()
    this.getCurrentUser()
    this.locatorButtonPressed()
  },
  data () {
    return {
      media: [],
      device: Capacitor.getPlatform() || 'web',
      paymentError: false,
      paymentInProgress: false,
      user: {
        payment: ''
      },
      paypalPayment: '',
      availablePayments: [],
      selectedServicePrice: 0,
      totalPrice: 0,
      discountPrice: 0,
      theDates: [],
      nextWeek: true,
      previousWeek: false,
      currentWeek: '',
      center: { lat: 45.508, lng: -73.587 },
      currentPlace: null,
      categories: [],
      theCategory: 'all',
      searchCompany: '',
      theRadius: 'any',
      radiuses: {
        any: this.$t('customer_.any'),
        0.5: '500m',
        1: '1km',
        2: '2km',
        3: '3km',
        5: '5km',
        10: '10km',
        20: '20km',
        50: '50km'
      },
      apiToken: localStorage.getItem('customer_api_token') || '',
      columnDefs: null,
      rowData: [],
      modules: AllCommunityModules,
      headers: [
        { text: this.$t('customer_.date'), value: 'start' },
        { text: 'ID', value: 'company' },
        { text: this.$t('customer_.service'), value: 'service' },
        { text: this.$t('customer_.employee'), value: 'employee_name' },
        { text: this.$t('customer_.total_to_pay'), value: 'total_to_pay', align: 'center' },
        { text: this.$t('customer_.total_payed'), value: 'total_payed', align: 'center' },
        { text: this.$t('employees_list.actions'), value: 'actions', align: 'right' }
      ],
      search: '',
      emailTo: '',
      emailText: '',
      currentUserEmail: '',
      singleMessage: '',
      optionsServices: [],
      searcheS: '',
      searche: '',
      selectedCompany: '',
      selectedCompanyName: '',
      displayCompanies: [],
      activeServices: [],
      activeServicesId: [],
      companyEmployees: [],
      companyInfo: {},
      companyProgram: [],
      selectedEmployee: '',
      companyEmployeesPictures: [],
      companyActiveEmployee: [],
      companyServices: [],
      priceCurrency: '',
      customerLatitude: '',
      customerLongitude: '',
      infoContent: '',
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      timer: null,
      visiblePayments: false,
      selectedDay: '',
      selectedTimeStart: '',
      selectedTimeEnd: '',
      paymentPrice: '',
      paymentCurrency: '',
      selectedServiceName: '',
      revolutInstance: '',
      descriptionEvent: '',
      meetingId: null
    }
  },
  methods: {
    gotoMeet (meetingId) {
      this.meetingId = meetingId
      this.$bvModal.show('meet')
    },
    closeMeet () {
      this.meetingId = false
    },
    openGallery (index) {
      this.$refs.lightbox.showImage(index)
    },
    payWithIOS (client) {
      // save payment
      this.user.notification_sms = 1
      this.user.notification_email = 1
      this.user.on_hold = 0
      const userName = this.user.name.split(' ')
      this.user.fname = userName[0]
      this.user.lname = userName[1]
      const dataPost = {
        company: this.selectedCompany,
        employee: this.selectedEmployee,
        service: this.activeServicesId,
        selectedDay: this.selectedDay,
        selectedTimeStart: this.selectedTimeStart,
        selectedTimeEnd: this.selectedTimeEnd,
        client: client,
        currency: this.priceCurrency,
        amount: this.totalPrice,
        subtotal: this.selectedServicePrice,
        discount: 0,
        voucher: '',
        transaction: ''
      }
      Axios.post(API.API_ADD_BOOKING_IOS, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            const bookingToken = response.data.token
            // redirect to payment page
            const openCapacitorSite = async () => {
              await Browser.open({ url: 'https://app.12booky.com/app/payBooking/' + bookingToken })
            }
            console.log(openCapacitorSite())
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
        })
    },
    makeBooking (client, transaction = null) {
      this.user.notification_sms = 1
      this.user.notification_email = 1
      this.user.on_hold = 0
      const userName = this.user.name.split(' ')
      this.user.fname = userName[0]
      this.user.lname = userName[1]
      const dataPost = {
        company: this.selectedCompany,
        employee: this.selectedEmployee,
        service: this.activeServicesId,
        selectedDay: this.selectedDay,
        selectedTimeStart: this.selectedTimeStart,
        selectedTimeEnd: this.selectedTimeEnd,
        client: client,
        currency: this.priceCurrency,
        amount: this.totalPrice,
        subtotal: this.selectedServicePrice,
        discount: 0,
        voucher: '',
        transaction: transaction,
        description: this.descriptionEvent
      }
      Axios.post(API.API_ADD_BOOKING, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            core.showSnackbar('success', this.$t('booking.booking_successfull'))
            this.getBookings()
            this.$bvModal.hide('reschedule')
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
        })
    },
    getAvailablePayments (companyToken) {
      if (this.device !== 'ios') {
        const dataPost = {
          company: companyToken
        }
        Axios.post(API.API_GET_AVAILABLE_PAYMENTS, dataPost)
          .then((response) => {
            if (response.data.status === 'success') {
              if (response.data.data.available_payments) {
                this.availablePayments = response.data.data.available_payments
              }
              this.priceCurrency = response.data.data.payment_currency
              if (response.data.data.payment_test) {
                this.testPayment = response.data.data.payment_test
              }
              if (response.data.data.payment_paypal) {
                const paymentPaypal = JSON.parse(response.data.data.payment_paypal)
                if (paymentPaypal.active === true) {
                  const paypalUserId = (this.testPayment === '1') ? paymentPaypal.address_test : paymentPaypal.address
                  this.initializePaypal(paypalUserId)
                }
              }
              if (response.data.data.payment_revolut) {
                const paymentRevolut = JSON.parse(response.data.data.payment_revolut)
                if (paymentRevolut.active === true) {
                  this.revolutUserId = (this.testPayment === '1') ? paymentRevolut.api_sandbox : paymentRevolut.api
                  this.revolutInstance = (this.testPayment === '1') ? 'sandbox' : 'prod'
                  this.initializeRevolut()
                }
              }
              if (response.data.data.payment_stripe) {
                const paymentStripe = JSON.parse(response.data.data.payment_stripe)
                if (paymentStripe.active === true) {
                  this.stripeUserId = (this.testPayment === '1') ? paymentStripe.test_publishable : paymentStripe.live_publishable
                }
              }
            }
          })
          .catch((error) => {
            core.showSnackbar('error', error.response)
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'auth1.login' })
            }
          })
      }
    },
    tokenCreated (token) {
      if (token.id) {
        this.sendPaymentToStripe(token)
      }
    },
    payWithStripe () {
      this.paymentError = false
      this.paymentInProgress = true
      this.$refs.elementRef.submit()
    },
    sendPaymentToStripe (token) {
      const dataPost = {
        token: token,
        currency: this.priceCurrency,
        amount: this.totalPrice,
        company: this.selectedCompany
      }
      Axios.post(API.API_SET_STRIPE_ORDER, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.makeBooking(this.user, response.data.response)
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            this.paymentError = error.response.data.message
            this.paymentInProgress = false
          } else if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    initializeRevolut () {
      const script1 = document.createElement('script')
      script1.text = '!function(e,o,t){e[t]=function(n,r){var c={sandbox:"https://sandbox-merchant.revolut.com/embed.js",prod:"https://merchant.revolut.com/embed.js",dev:"https://merchant.revolut.codes/embed.js"},d=o.createElement("script");d.id="revolut-checkout",d.src=c[r]||c.prod,d.async=!0,o.head.appendChild(d);var s={then:function(r,c){d.onload=function(){r(e[t](n))},d.onerror=function(){o.head.removeChild(d),c&&c(new Error(t+" is failed to load"))}}};return"function"==typeof Promise?Promise.resolve(s):s}}(window,document,"RevolutCheckout");'
      document.head.appendChild(script1)
    },
    createRevolutApiOrder () {
      const dataPost = {
        amount: this.totalPrice * 100,
        currency: this.priceCurrency,
        userId: this.revolutUserId,
        instanceType: this.revolutInstance
      }

      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_SET_REVOLUT_ORDER, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            if (response.data.response.public_id) {
              this.setRevolutLoaded(response.data.response.public_id, this.revolutInstance, response.data.response)
            } else {
              core.showSnackbar('error', this.$t('booking.check_api_key'))
            }
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    setRevolutLoaded (apiKey, instanceType, theResponse) {
      window.RevolutCheckout(apiKey, instanceType).then(function (instance) {
        instance.payWithPopup({
          locale: 'en',
          onSuccess () {
            this.makeBooking(this.user, theResponse)
          },
          onError (message) {
            core.showSnackbar('error', message)
          }
        })
      })
    },
    initializePaypal (userId) {
      const theScript = document.getElementById('paypalScript')
      if (theScript !== null) {
        document.head.removeChild(theScript)
      }
      const script = document.createElement('script')
      script.id = 'paypalScript'
      script.src = 'https://www.paypal.com/sdk/js?client-id=' + userId + '&currency=EUR'
      script.addEventListener('load', this.setPaypalLoaded)
      document.head.appendChild(script)
    },
    setPaypalLoaded () {
      const paymentDescription = this.selectedServiceName + ' - ' + this.selectedTimeStart + ' ' + this.selectedDay
      const totalToPay = (this.priceCurrency === 'RON') ? Math.floor(this.totalPrice / 5) : this.totalPrice
      const clientInfo = this.user
      const currentInstance = this
      window.paypal.Buttons({
        onClick: function (data, actions) { },
        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: [
              {
                description: paymentDescription,
                amount: {
                  value: totalToPay
                }
              }
            ]
          })
        },
        onApprove: function (data, actions) {
          return actions.order.capture().then(function (details) {
            if (details.status === 'COMPLETED') {
              // submit the forms with the rest of the infos
              currentInstance.makeBooking(clientInfo, details)
            } else {
              core.showSnackbar('error', this.$t('booking.error_payment'))
            }
          })
        },
        onError: (error) => {
          core.showSnackbar('error', error)
        }
      }).render('#paypalPayment')
    },
    setDate (item1, item) {
      const hasBeenSelected = document.getElementsByClassName('hasBeenSelected')
      if (hasBeenSelected.length) {
        for (let i = 0; i < hasBeenSelected.length; i++) {
          hasBeenSelected[i].classList.remove('hasBeenSelected')
        }
      }
      document.getElementById(item1.theDateSend + '_' + item.start).classList.add('hasBeenSelected')
      this.selectedDay = item1.theDateSend
      this.selectedTimeStart = item.start
      this.selectedTimeEnd = item.end
      this.enablePayments()
      setTimeout(() => {
        const objDiv = document.getElementById('reschedule')
        objDiv.scrollTop = objDiv.scrollHeight
      }, 200)
    },
    enablePayments () {
      this.visiblePayments = true
    },
    setWeek (week) {
      if (this.selectedCompany && this.$refs.employeeToken.value && this.activeServicesId) {
        this.getAvailability(week)
      }
    },
    getAvailability (week) {
      this.isLoadingEvent = true

      const dataPost = {
        company: this.selectedCompany,
        employee: this.$refs.employeeToken.value,
        service: this.activeServicesId,
        week: week
      }
      Axios.post(API.API_GET_AVAILABILITY_BY_EMPLOYEE, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.availability = response.data.availability
            this.previousWeek = this.availability.previousWeek
            this.nextWeek = this.availability.nextWeek
            this.currentWeek = this.availability.currentWeek
            this.theDates = this.availability.theDates
          }
        })
        .catch((error) => {
          this.isLoadingEvent = false
          core.showSnackbar('error', error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    selectCompany (item) {
      this.getCompanyInfos(item.token)
      this.selectedCompany = item.token
      this.getAvailablePayments(item.token)
      this.selectedCompanyName = item.name
      this.visiblePayments = false
      this.activeServices = []
      this.activeServicesId = []
      this.selectedEmployee = []
      this.$refs.eventID.value = null
      this.$refs.employeeToken.value = ''
      this.$refs.employeeName.innerHTML = this.$t('employees_list.add_employee')
      this.$refs.employeeEmail.innerHTML = ''
      this.$refs.employeeEmail.setAttribute('token', '')
      this.$refs.employeeLogo.setAttribute('src', '')
      this.$bvModal.hide('newBooking')
      this.$bvModal.show('addNewBooking')
    },
    showInMap (item) {
      this.infoWindowPos = item.position
      this.infoContent = this.getInfoWindowContent(item)
      this.infoWinOpen = true
    },
    toggleInfoWindow (marker, idx) {
      this.infoWindowPos = marker.position
      this.infoContent = this.getInfoWindowContent(marker)
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
    getInfoWindowContent (marker) {
      return (`<div class="card">
        <div class="card-image">
          <figure class="image is-4by3">
            <img src="${marker.logo || require('../../assets/images/12bookylogo.png')}" alt="${marker.name}" style=" height: 50px;width: 150px; object-fit: contain;">
          </figure>
        </div>
        <div style="font-weight: bold; color: black;">${marker.name}</div>
        <div class="content"> ${marker.description} </div>
      </div>`)
    },
    setCompany (token) {
      this.selectedCompany = token
    },
    locatorButtonPressed () {
      console.log(Capacitor.getPlatform())
      if (Capacitor.getPlatform() !== 'web') {
        Geolocation.requestPermissions()
        const printCurrentPosition = async () => {
          const coordinates = await Geolocation.getCurrentPosition()
          console.log('Current position:')
          console.log(coordinates)
          this.center.lat = coordinates.coords.latitude
          this.center.lng = coordinates.coords.longitude
        }
        console.log(printCurrentPosition())
      } else {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.center.lat = position.coords.latitude
            this.center.lng = position.coords.longitude
            console.log(position)
          },
          error => {
            console.log(error.message)
          }
        )
      }
    },
    addNewBooking () {
      this.getCompanies('', 'all', '20')
      this.$bvModal.show('newBooking')
    },
    updateCompanies () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.getCompanies(this.searchCompany, this.theCategory, this.theRadius)
      }, 800)
    },
    getCompanies (input, category, radius) {
      const postData = {
        radius: radius,
        name: input,
        category: category,
        latitude: this.center.lat || '',
        longitude: this.center.lng || ''
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_CUSTOMER_GET_COMPANIES, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.displayCompanies = response.data.companies
          }
        })
        .catch((error) => {
          console.error(error.response)
        })
    },
    getBookings () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.get(API.API_CUSTOMER_GET_BOOKINGS)
        .then((response) => {
          if (response.data.status === 'success') {
            this.rowData = response.data.bookings
            console.log(response.data.companyTypes.length)
            for (let i = 0; i < response.data.companyTypes.length; i++) {
              const variable = response.data.companyTypes[i]
              this.categories.push({
                value: variable,
                text: this.$t('helper.' + variable)
              })
            }
          }
        })
        .catch((error) => {
          console.error(error.response)
        })
    },
    removeData (token) {
      this.$bvModal.msgBoxConfirm(this.$t('customer_.booking_delete_message'))
        .then(value => {
          this.boxOne = value
          if (value === true) {
            const formData = {
              token: token
            }
            Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
            Axios.post(API.API_CUSTOMER_DELETE_BOOKING, formData)
              .then((response) => {
                if (response.data.status === 'success') {
                  core.showSnackbar('success', this.$t('customer_.booking_delete_success'))
                  this.rowData = response.data.bookings
                }
              })
              .catch((error) => {
                console.error(error.response)
                if (error.response.status === 401) {
                  this.$router.push({ name: 'auth1.login' })
                }
              })
          }
        })
    },
    appendMessage (item) {
      this.getTheMessagesFrom(this.currentUserEmail, item.employee_email)
      // set as read
      this.emailTo = item.employee_email
      this.emailText = ''
      this.$bvModal.show('message')
    },
    getTheMessagesFrom (from, to) {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const postData = {
        from: from,
        to: to
      }
      Axios.post(API.API_CUSTOMER_GET_MESSAGES_FROM, postData)
        .then((response) => {
          if (response.data.status === 'return') {
            core.showSnackbar('error', response.data.message)
          } else if (response.data.status === 'success') {
            this.singleMessage = response.data.messages
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    sendMessageTo () {
      if (this.emailText === '') {
        core.showSnackbar('error', this.$t('messages.not_empty'))
      } else {
        Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
        const theMessage = {
          to: this.emailTo,
          message: this.emailText
        }
        Axios.post(API.API_CUSTOMER_SEND_MESSAGE, theMessage)
          .then((response) => {
            if (response.data.status === 'return') {
              core.showSnackbar('error', response.data.message)
            } else if (response.data.status === 'success') {
              core.showSnackbar('success', response.data.message)
              // refresh the message
              this.getTheMessagesFrom(this.emailTo, this.currentUserEmail)
              this.emailText = ''
            }
          })
          .catch((error) => {
            console.error(error.response)
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'auth1.login' })
            }
          })
      }
    },
    getCurrentUser () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.get(API.API_CUSTOMER_GET_CUSTOMER)
        .then((response) => {
          if (response.data.status === 'success') {
            this.user = JSON.parse(response.data.user)
            this.currentUserEmail = this.user.email
          }
        }).catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    reschedule (item) {
      this.visiblePayments = false
      this.totalPrice = 0
      this.selectedServicePrice = 0
      this.selectedCompany = item.companyToken
      this.selectedCompanyName = item.company
      this.activeServices = item.serviceObj || []
      this.activeServicesId = []
      if (item.serviceObj) {
        for (let i = 0; i < item.serviceObj.count; i++) {
          this.activeServicesId.push(item.serviceObj[i].token)
        }
      }
      this.updatePriceAndDate()
      this.getCompanyEmployees(item.companyToken)
      this.$refs.eventID.value = item.token
      this.$refs.employeeToken.value = item.employee_token ? item.employee_token : ''
      this.selectedEmployee = item.employee_token ? item.employee_token : ''
      this.$refs.employeeName.innerHTML = item.employee_name ? item.employee_name : this.$t('employees_list.add_employee')
      this.$refs.employeeEmail.innerHTML = item.employee_email ? item.employee_email : ''
      this.$refs.employeeEmail.setAttribute('token', item.employee_token)
      if (item.employee_logo !== null) {
        this.$refs.employeeLogo.setAttribute('src', item.employee_logo)
      }
      this.setWeek()
      this.getAvailablePayments(item.companyToken)
      this.$bvModal.show('reschedule')
    },
    changeServices () {
      this.getCompanyServices()
      this.$bvModal.show('theServices')
    },
    getCompanyServices () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const postData = {
        company: this.selectedCompany
      }
      Axios.post(API.API_CUSTOMER_SERVICES, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            const optionServicesObj = []
            for (let i = 0; i < response.data.services.length; i++) {
              for (let j = 0; j < response.data.services[i].list.length; j++) {
                optionServicesObj.push(response.data.services[i].list[j])
              }
            }
            this.optionsServices = optionServicesObj
            this.priceCurrency = response.data.currency
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getCompanyEmployees (companyToken) {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const postData = {
        company: companyToken
      }
      Axios.post(API.API_CUSTOMER_EMPLOYEES, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.companyEmployees = response.data.employees
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    getCompanyInfos (companyToken) {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      const postData = {
        company: companyToken
      }
      Axios.post(API.API_CUSTOMER_GET_COMPANY_INFO, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.companyInfo = response.data.companyinfo
            this.media = this.companyInfo.servicesList
            this.companyProgram = this.companyInfo.program
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    filteredListService () {
      const searchValue11 = this.searcheS.toLowerCase()
      if (searchValue11.length > 0) {
        for (let i = 0; i < this.optionsServices.length; i++) {
          if (this.optionsServices[i].name.toLowerCase().includes(searchValue11)) {
            this.$refs['services_' + this.optionsServices[i].id][0].$el.classList.remove('d-none')
          } else {
            this.$refs['services_' + this.optionsServices[i].id][0].$el.classList.add('d-none')
          }
        }
      } else {
        for (let i = 0; i < this.optionsServices.length; i++) {
          this.$refs['services_' + this.optionsServices[i].id][0].$el.classList.remove('d-none')
        }
      }
    },
    filteredListEmployee () {
      const searchValue1 = this.searche.toLowerCase()
      if (searchValue1.length > 0) {
        for (let i = 0; i < this.companyEmployees.length; i++) {
          if (this.companyEmployees[i].name.toLowerCase().includes(searchValue1)) {
            this.$refs['employee_' + this.companyEmployees[i].id][0].$el.classList.remove('d-none')
          } else {
            this.$refs['employee_' + this.companyEmployees[i].id][0].$el.classList.add('d-none')
          }
        }
      } else {
        for (let i = 0; i < this.companyEmployees.length; i++) {
          this.$refs['employee_' + this.companyEmployees[i].id][0].$el.classList.remove('d-none')
        }
      }
    },
    setEmployee (item) {
      this.selectedEmployee = item.token
      this.$refs.employeeToken.value = item.token
      this.$refs.employeeName.innerHTML = item.name
      this.$refs.employeeEmail.innerHTML = item.email
      this.$refs.employeeEmail.setAttribute('token', item.token)
      this.$refs.employeeLogo.setAttribute('src', item.logo)
      this.setWeek()
      this.$bvModal.hide('theEmployee')
    },
    setService (item) {
      if (!this.activeServices) {
        this.activeServices = []
        this.activeServicesId = []
      }
      this.activeServices.push(item)
      this.activeServicesId.push(item.token)
      this.updatePriceAndDate()
      this.setWeek()
      this.$bvModal.hide('theServices')
    },
    removeService (item) {
      const idx = this.activeServices.indexOf(item)
      const idx1 = this.activeServicesId.indexOf(item.token)
      this.activeServices.splice(idx, 1)
      this.activeServicesId.splice(idx1, 1)
      this.updatePriceAndDate()
      this.setWeek()
    },
    changeEmployee () {
      // this.getCompanyEmployees()
      this.$bvModal.show('theEmployee')
    },
    updatePriceAndDate () {
      if (this.activeServices.length > 0) {
        this.activeServicesId = []
        this.selectedServicePrice = 0
        this.totalPrice = 0
        for (let x = 0; x < this.activeServices.length; x++) {
          this.activeServicesId.push(this.activeServices[x].token)
          const addComma = (x > 0) ? ', ' : ''
          this.selectedServiceName += addComma + this.activeServices[x].name
          this.selectedServicePrice = this.selectedServicePrice + parseFloat(this.activeServices[x].price)
        }
        this.totalPrice = this.selectedServicePrice
      } else {
        this.activeServicesId = []
        this.selectedServiceName = ''
        this.selectedServicePrice = 0
        this.totalPrice = 0
      }
      console.log(this.selectedServiceName)
      this.getAvailablePayments(this.selectedCompany)
    },
    translatePrice (price, currency) {
      const postData = {
        price: price,
        currency: currency
      }
      Axios.post(API.API_CUSTOMER_GET_TRANSLATED_PRICE, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            this.paymentPrice = response.data.value
            this.paymentCurrency = response.data.currency
          }
        })
        .catch((error) => {
          console.error(error.response)
        })
    }
  }
}
</script>
<style>
.dateButton{
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  padding-bottom: 5px;
  background: #e9ecef;
  color: #646464;
  border-radius: 5px;
}
.dateButton:hover{
  background: #0c88b4;
  color: white;
}
.hasBeenSelected{
  background: #0c88b4;
  color: white;
}
.theMessage{
  width: 100%;
  margin-bottom: 10px;

}
.theMessage.theMessageCurrent{
  text-align: right;
}
.theMessage.theMessageCurrent > span{
  background: #69e384;
}
.theMessage > span{
  padding: 5px;
  border: 1px solid;
  border-radius: 5px;
  color: #275ab7;
  display: inline-table;
}
.theDate{
  font-size:10px;
  color: black;
}
.employee_logo{
  width: 100%;
}
.displayCompanies{
  border-bottom: 1px solid #aaaaaa;
}
.displayCompanies:hover{
  background: #ececec;
}
.company_name{
  font-size: 16px;
  font-weight: bold;
  font-family: Arial;
  display: block;
  color: black;
  line-height: 22px;
}
.company_address{
  font-size: 12px;
  color: #808080;
}
.description {
  line-height: 20px !important;
}
#addNewBooking .modal-content {
  background: #eaf5ff;
}
.btn-mic {
  font-size: 10px !important;
  text-decoration: underline;
}
</style>
